import { Loader } from "@/components/ui";
import "./connectPromotionAd.scss";

import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";



import ConnectPromotionAdPopup from "./ConnectPromotionAd";

import { viewList } from "../../pages/accounts/model/profiles/card/components/upAd/viewList";
import { Icon } from "../../ui";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";

const PromotionTablePopup = (props) => {
  const {
    city,
    slug,
    position,
    setPrice,
    setPosition,
    setPromotion,
    open,
    setOpen,
    name,
    positionPrice,
    city_id,
    dynamicTop,
    setData: setProfilesData,
  } = props;

  console.log('PromotionTablePopup' , props)

  const { t } = useLadyService();

  const [currentPosition, setCurrentPosition] = useState(position);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectPosition, setSelectPosition] = useState(null);
  const [selectPrice, setSelectPrice] = useState(null);

  const [isOpenUp, setIsOpenUp] = useState(false);

  const handleOpenUp = ({ place, price }) => {
    setSelectPrice(price);
    setSelectPosition(place);
    setIsOpenUp(true);
  };

  const postCity = t("incity")?.replace(/%([^%]+)%/g, city);

  const calculatePercentageValue = ({ currentPosition, position }) => {
    if (!currentPosition || !data.length) {
      return null;
    } else {
      const mt = viewList[currentPosition - 1]["view"];

      const mp = viewList[position - 1]["view"];

      const res = Math.floor(((mp - mt) / mt) * 100);

      return res;
    }
  };

  const calculatePercentageWidth = (max, current) => {
    return (current / max) * 100;
  };

  const calcFirst = calculatePercentageValue({
    currentPosition,
    position: 1,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `places/${slug}`;

        const method = "GET";

        const { positions } = await makeRequest({ route, method });
        const { available, current } = positions;

        const availableRes = available.map((i, index) => {
          return { place: "fill", price:  index + 1 >= current ? i : i + 1 };
        });
        // let freePlace = {}
        // if (available[current]) {
        //   freePlace = {place: "free", price: 1};
        // }
        // const newData = availableRes.concat([]);
        setData(availableRes);

        setCurrentPosition(current);
        setIsLoading(false);
      } catch (error) {}
    };

    fetchData();
  }, [slug]);

  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        container={'div'}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h3 className="m-12-24">
              {t("currentprices")} {postCity}
            </h3>
            <div>
              <div className="up-table__header">
                <span>{t("place")}</span>
                <span>{t("price")}</span>
                <span>{t("plustoviews")}*</span>
              </div>
              <div className={"up-table__body"}>
                {data.map((item, index) => {
                  const calc = calculatePercentageValue({
                    currentPosition,
                    position: index + 1,
                  });

                  const width = calculatePercentageWidth(calcFirst, calc);

                  return item.place === "fill" ? (
                    <div
                      key={index}
                      className={
                        index + 1 === currentPosition
                          ? "up-table__row up-table__row__zero"
                          : item.place === "free" && index + 1 > currentPosition
                            ? "up-table__row up-table__row__last"
                            : "up-table__row"
                      }
                      onClick={() =>
                        handleOpenUp({place: index + 1, price: item.price})
                      }
                    >
                      <span>{index + 1}</span>

                      <span>
                      {item.price}
                        <Icon
                          title={t("EroCoins")}
                          clazz={`color-main ml-4`}
                          spritePath={"erocoin"}
                          size={"xs"}
                        />
                    </span>

                      {index + 1 === currentPosition ? (
                        <span>{t("currentpos")}</span>
                      ) : (
                        <span
                          style={{"--percent": `${width > 0 ? width : 0}%`}}
                        >
                        {calc > 0 ? `+${calc} %` : `${calc} %`}
                      </span>
                      )}
                    </div>
                  ) : item.place === "free" ? (
                    <div
                      key={index}
                      className={"up-table__row up-table__row"}
                      onClick={() =>
                        handleOpenUp({place: index + 1, price: item.price})
                      }
                    >
                      <span>{index + 1}</span>

                      <span>
                      {item.price}
                        <Icon
                          title={t("EroCoins")}
                          clazz={`color-main ml-4`}
                          spritePath={"erocoin"}
                          size={"xs"}
                        />
                    </span>

                      <span style={{"--percent": `${width > 0 ? width : 0}%`}}>
                      {calc > 0 ? `+${calc} %` : `${calc} %`}
                    </span>
                    </div>
                  ) : null;
                })}

                {!positionPrice && (
                  <>
                    <div className="up-table__row up-table__row__current">
                      <span>&mdash;</span>
                      <span>&mdash;</span>
                      <span>&mdash;</span>
                    </div>

                    <div className="up-table__row up-table__row__zero__last">
                      <span>{currentPosition}</span>

                      <span>—</span>

                      <span>{t("currentpos")}</span>
                    </div>
                  </>
                )}
              </div>

              <span className="p3">*{t("nearvalue")}</span>
            </div>
          </>
        )}
      </Popup>

      {isOpenUp && (
        <ConnectPromotionAdPopup
          open={isOpenUp}
          setOpen={setIsOpenUp}
          setOpenAll={setOpen}
          position={selectPosition}
          price={selectPrice}
          currentPrice={data[position - 1]?.price || 0}
          data={data}
          currentPosition={currentPosition}
          slugProfile={slug}
          setPrice={setPrice}
          setPromotion={setPromotion}
          setPosition={setPosition}
          name={name}
          city_id={city_id}
          dynamicTop={dynamicTop}
          setProfilesData={setProfilesData}
        />
      )}
    </>
  );
};

export default PromotionTablePopup;
